/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { ProjectSettingsJobsView } from "./View";
import { axios } from "../../utils/request";
import { API } from "../../constants/api";

export const ProjectSettingsJobs = () => {
  const [isJobsEnabled, setIsJobsEnabled] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const api = API.PROJECT_SETTINGS_JOBS_GET_STATUS();
    axios
      .post(api)
      .then(({ data }) => {
        setIsJobsEnabled(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleJobsEnable = async () => {
    setUpdating(true);

    const api = API.PROJECT_SETTINGS_JOBS_SET_STATUS();

    axios
      .post(api, { status: !isJobsEnabled })
      .then(() => {
        setIsJobsEnabled((p) => !p);
        setUpdating(false);
      })
      .catch((error) => {
        console.error(error);
        setUpdating(false);
      });
  };

  return (
    <ProjectSettingsJobsView
      handleJobsEnable={handleJobsEnable}
      isJobsEnabled={isJobsEnabled}
      updating={updating}
      loading={loading}
    />
  );
};
