/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isRequestError,
  getErrorMessage,
  isRequestSuccess,
  isRequestPending,
} from "../../utils/store";
import { addStatisticsUsersBalance } from "../../actions/statisticsUsersAddBalance";
import { StatisticsUsersAddBalanceView } from "./View";
import { axios } from "../../utils/request";
import { API } from "../../constants/api";

const DEFAULT_CURRENCY = {
  tid: "BALANCES.ADD_USERS_BALANCE.CURRENCIES.ALL",
  value: "ALL",
  id: 1,
};

export const StatisticsUsersAddBalance = () => {
  const [usersInput, setUsersInput] = useState("");
  const [sumInput, setSumInput] = useState(200);

  const [nicknameIsLoading, setNicknameIsLoading] = useState(false);
  const [nicknameStatuses, setNicknameStatuses] = useState([]);
  const [nicknameTimer, setNicknameTimer] = useState(null);

  const [selectedCurrency, setSelectedCurrency] = useState(DEFAULT_CURRENCY);
  const [currencies, setCurrencies] = useState([]);

  const { isLoaded, isLoading, isError, errorMessage } = useSelector(
    ({ statisticsUsersAddBalance }) => ({
      errorMessage: getErrorMessage(statisticsUsersAddBalance.request),
      isLoading: isRequestPending(statisticsUsersAddBalance.request),
      isLoaded: isRequestSuccess(statisticsUsersAddBalance.request),
      isError: isRequestError(statisticsUsersAddBalance.request),
    })
  );

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const sum = sumInput;
    const currency = selectedCurrency.value;
    const usernames = usersInput.split(",").map((item) => item.trim());
    dispatch(addStatisticsUsersBalance({ usernames, sum, currency }));
  };

  const handleChangeUsers = async (e) => setUsersInput(e.target.value);
  const handleChangeSum = async (e) => setSumInput(e.target.value);

  const fetchNicknames = async () => {
    setNicknameIsLoading(true);
    await (async () => {
      try {
        if (!usersInput.length) return null;
        const body = { nicknames: usersInput };
        const response = await axios.post(API.BALANCES_GET_NICKNAMES, body);
        const { data } = response;
        if (!data) return null;
        const currentNames = usersInput.split(",").map((n) => n.trim());
        const nicknames = currentNames.map((n) => ({ finded: data[n], n }));
        setNicknameStatuses(nicknames);
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    })();
    setNicknameIsLoading(false);
  };

  const fetchCurrencies = async () => {
    const response = await axios.post(API.BALANCES_GET_CURRENCIES, {});
    const { data } = response;
    setCurrencies([
      DEFAULT_CURRENCY,
      ...data.map((currency, i) => ({
        value: currency.code,
        tid: currency.name,
        id: i + 2,
      })),
    ]);
  };

  useEffect(() => {
    if (nicknameTimer) clearTimeout(nicknameTimer);
    const timer = setTimeout(fetchNicknames, 500);
    setNicknameTimer(timer);
    return () => clearTimeout(timer);
  }, [usersInput]);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const getLoading = () => isLoading || nicknameIsLoading;

  const isNicknameNotFound = nicknameStatuses.find((n) => !n.finded);

  const isButtonDisabled = !usersInput || isNicknameNotFound;

  return (
    <StatisticsUsersAddBalanceView
      setSelectedCurrency={setSelectedCurrency}
      handleChangeUsers={handleChangeUsers}
      isButtonDisabled={isButtonDisabled}
      nicknameStatuses={nicknameStatuses}
      selectedCurrency={selectedCurrency}
      handleChangeSum={handleChangeSum}
      errorMessage={errorMessage}
      handleSubmit={handleSubmit}
      usersInput={usersInput}
      currencies={currencies}
      loading={getLoading()}
      isLoaded={isLoaded}
      error={isError}
      sum={sumInput}
    />
  );
};
