/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { ProjectSettingsTechView } from "./View";
import { axios } from "../../utils/request";
import { API } from "../../constants/api";

export const ProjectSettingsTech = () => {
  const [isTechEnabled, setIsTechEnabled] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const api = API.PROJECT_SETTINGS_TECH_GET_STATUS();
    axios
      .post(api)
      .then(({ data }) => {
        setIsTechEnabled(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleTechEnable = async () => {
    setUpdating(true);

    const api = API.PROJECT_SETTINGS_TECH_SET_STATUS();

    axios
      .post(api, { status: !isTechEnabled })
      .then(() => {
        setIsTechEnabled((p) => !p);
        setUpdating(false);
      })
      .catch((error) => {
        console.error(error);
        setUpdating(false);
      });
  };

  return (
    <ProjectSettingsTechView
      handleTechEnable={handleTechEnable}
      isTechEnabled={isTechEnabled}
      updating={updating}
      loading={loading}
    />
  );
};
