import styled from "styled-components";
import PropTypes from "prop-types";
import { t } from "i18next";
import React from "react";

import { Text, Loader, AlertSection } from "../../components";
import { SELECT_ROLE_VALUES } from "../../constants/static";
import { USER_ROLE_CHANGE } from "../../constants/fields";
import { ButtonPrimary } from "../../components/buttons";
import { IndentLayout } from "../../components/layouts";
import { SelectField } from "../../components/fields";
import { FormTitle } from "../../components/titles";
import { FieldGrid } from "../../components/grids";
import { Box } from "../../components/cards";

export const UserRoleChangeView = ({
  requestLoading,
  requestLoaded,
  errorMessage,
  setRoleValue,
  dataLoading,
  roleValue,
  disabled,
  error,
}) => {
  return (
    <React.Fragment>
      {requestLoading && <Loader />}
      <Box variant="outlined">
        <IndentLayout>
          <FormTitle tid="USER.USER_ROLE_CHANGE.FORM.TITLE" />
          <FieldGrid double>
            <SelectField
              loading={dataLoading}
              name={USER_ROLE_CHANGE.ROLE}
              value={roleValue}
              onChange={(e) => {
                setRoleValue(e.target.value);
              }}
              label={t("USER.USER_ROLE_CHANGE.FORM.ROLE")}
              items={SELECT_ROLE_VALUES}
            />
          </FieldGrid>
          <SubmitButton
            size="large"
            variant="outlined"
            color="primary"
            disabled={disabled}
            type="submit"
          >
            <Text tid="USER.USER_ROLE_CHANGE.FORM.BUTTON_SUBMIT" />
          </SubmitButton>
        </IndentLayout>
      </Box>
      <AlertSection
        success={requestLoaded}
        successMessage="USER.USER_ROLE_CHANGE.FORM.ALERT_SUCCESS"
        error={error}
        errorMessage={errorMessage}
      />
    </React.Fragment>
  );
};

const SubmitButton = styled(ButtonPrimary)`
  && {
    height: 56px;
  }
`;

UserRoleChangeView.propTypes = {
  disabled: PropTypes.bool,
  requestLoading: PropTypes.bool,
  requestLoaded: PropTypes.bool,
  dataLoading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};
