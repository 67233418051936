import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { SkeletonDataList } from "../../components/skeletons";
import { SelectField, TextField } from "../../components/fields";
import { Text, AlertSection, Loader } from "../../components";
import { ButtonPrimary } from "../../components/buttons";
import { IndentLayout } from "../../components/layouts";
import { ListTitle } from "../../components/titles";
import { FieldGrid } from "../../components/grids";
import { Box } from "../../components/cards";

export const StatisticsUsersAddBalanceView = ({
  setSelectedCurrency,
  handleChangeUsers,
  isButtonDisabled,
  nicknameStatuses,
  selectedCurrency,
  handleChangeSum,
  errorMessage,
  handleSubmit,
  currencies,
  usersInput,
  isLoaded,
  loading,
  error,
  sum,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {loading && <Loader />}
      <Box variant="outlined">
        <IndentLayout>
          <ListTitle tid="BALANCES.ADD_USERS_BALANCE.TITLE" offset={3} />
          <p style={{ marginBottom: "16px" }}>
            <Text tid="BALANCES.ADD_USERS_BALANCE.HINT" />
          </p>
          {currencies?.length > 0 ? (
            <>
              {nicknameStatuses.length ? (
                <Nicknames>
                  {nicknameStatuses.map(({ finded, n }) =>
                    finded ? (
                      <NicknameFinded>{n}</NicknameFinded>
                    ) : (
                      <NicknameNoFinded>{n}</NicknameNoFinded>
                    )
                  )}
                </Nicknames>
              ) : null}

              <FieldGrid>
                <TextField
                  value={usersInput}
                  onChange={handleChangeUsers}
                  label={<Text tid="BALANCES.ADD_USERS_BALANCE.USERS" />}
                  required
                  type="text"
                />
              </FieldGrid>
              <FieldGrid double>
                <TextField
                  value={sum}
                  onChange={handleChangeSum}
                  label={t("BALANCES.ADD_USERS_BALANCE.SUM")}
                  required
                  type="text"
                />
                <SelectField
                  // loading={dataLoading}
                  value={selectedCurrency.value}
                  onChange={(e) => {
                    setSelectedCurrency({ value: e.target.value });
                  }}
                  label={t("BALANCES.ADD_USERS_BALANCE.CURRENCIES.LABEL")}
                  items={currencies}
                />
              </FieldGrid>
              <ButtonContainer>
                <ButtonPrimary
                  onClick={handleSubmit}
                  variant="outlined"
                  disabled={isButtonDisabled || loading}
                >
                  <Text tid="BALANCES.ADD_USERS_BALANCE.BUTTON" />
                </ButtonPrimary>
              </ButtonContainer>
            </>
          ) : (
            <SkeletonDataList size="medium" />
          )}
          <AlertSection
            success={isLoaded}
            successMessage="BALANCES.ADD_USERS_BALANCE.ALERT_SUCCESS"
          />
        </IndentLayout>
      </Box>
      <AlertSection error={error} errorMessage={errorMessage} offset={0} />
    </React.Fragment>
  );
};

const Nicknames = styled.div`
  margin-bottom: 24px;
  margin-right: 14px;
  margin-left: 14px;
  column-gap: 6px;
  display: flex;
  row-gap: 3px;
`;

const NicknameFinded = styled.span`
  color: green;
`;

const NicknameNoFinded = styled.span`
  color: red;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
`;
