import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthRoute, HomeRoute, NonAuthRoute } from "./route";
import { ROUTES } from "../../constants/routes";
import { LogIn } from "../login";
import {
  PageLayout,
  SectionLayout,
  ResponsiveLayout,
  ContentLayout,
  UserInfoLayout,
} from "../../components/layouts";
import { UserList } from "../user-list";
import { StaffList } from "../staff-list";
import { UserFind } from "../user-find";
import { UserInfo } from "../user-info";
import { UserRoleChange } from "../user-role-change";
import { UserBlocking } from "../user-blocking";
import { PartnerApplicationList } from "../partner-application-list";
import { PartnerApplicationInfo } from "../partner-application-info";
import { PartnerApplicationStatus } from "../partner-application-status";
import { WithdrawList } from "../withdraw-list";
import { WithdrawInfo } from "../withdraw-info";
import { WithdrawStatus } from "../withdraw-status";
import { DepositList } from "../deposit-list";
import { DepositInfo } from "../deposit-info";
import { StatisticsProfit } from "../statistics-profit";
import { StatisticsUsersBalance } from "../statistics-users-balance";
import { StatisticsUsersAddBalance } from "../statistics-users-add-balance";
import { StatisticsBotsBalance } from "../statistics-bots-balance";
import { UserStatisticsInfo } from "../user-statistics-info";
import { ProjectSettingsBots } from "../project-settings-bots";
import { ProjectSettings } from "../project-settings";
import { Jobs } from "../jobs";
import { Storage } from "../storage";
import { ProjectSettingsJobs } from "../project-settings-jobs";
import { ProjectSettingsTech } from "../project-settings-tech";

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.HOME} element={<HomeRoute />} />
        <Route element={<NonAuthRoute />}>
          <Route
            path={ROUTES.LOGIN}
            element={
              <PageLayout align="center">
                <ContentLayout>
                  <ResponsiveLayout size="small">
                    <SectionLayout indent>
                      <LogIn />
                    </SectionLayout>
                  </ResponsiveLayout>
                </ContentLayout>
              </PageLayout>
            }
          />
        </Route>
        <Route element={<AuthRoute />}>
          <Route
            path={ROUTES.USER_LIST}
            element={
              <PageLayout align="top">
                <ContentLayout>
                  <ResponsiveLayout size="medium">
                    <SectionLayout indent>
                      <UserFind />
                      <UserList />
                    </SectionLayout>
                  </ResponsiveLayout>
                </ContentLayout>
              </PageLayout>
            }
          />
          <Route element={<AuthRoute />}>
            <Route
              path={ROUTES.USER_INFO()}
              element={
                <PageLayout>
                  <ContentLayout>
                    <ResponsiveLayout>
                      <UserInfoLayout isUser>
                        <UserInfo />
                        <UserRoleChange />
                        <UserStatisticsInfo />
                        <UserBlocking />
                      </UserInfoLayout>
                    </ResponsiveLayout>
                  </ContentLayout>
                </PageLayout>
              }
            />
          </Route>
          <Route element={<AuthRoute />}>
            <Route
              path={ROUTES.STAFF_LIST}
              element={
                <PageLayout align="top">
                  <ContentLayout>
                    <ResponsiveLayout size="medium">
                      <SectionLayout indent>
                        <StaffList />
                      </SectionLayout>
                    </ResponsiveLayout>
                  </ContentLayout>
                </PageLayout>
              }
            />
          </Route>
          <Route element={<AuthRoute />}>
            <Route
              path={ROUTES.PARTNER_APPLICATION_LIST}
              element={
                <PageLayout align="top">
                  <ContentLayout>
                    <ResponsiveLayout size="medium">
                      <SectionLayout indent>
                        <PartnerApplicationList />
                      </SectionLayout>
                    </ResponsiveLayout>
                  </ContentLayout>
                </PageLayout>
              }
            />
          </Route>
          <Route element={<AuthRoute />}>
            <Route
              path={ROUTES.PARTNER_APPLICATION_INFO()}
              element={
                <PageLayout>
                  <ContentLayout>
                    <ResponsiveLayout>
                      <SectionLayout indent>
                        <PartnerApplicationInfo />
                        <PartnerApplicationStatus />
                      </SectionLayout>
                    </ResponsiveLayout>
                  </ContentLayout>
                </PageLayout>
              }
            />
          </Route>
          <Route element={<AuthRoute />}>
            <Route
              path={ROUTES.WITHDRAW_LIST}
              element={
                <PageLayout align="top">
                  <ContentLayout>
                    <ResponsiveLayout size="medium">
                      <SectionLayout indent>
                        <WithdrawList />
                      </SectionLayout>
                    </ResponsiveLayout>
                  </ContentLayout>
                </PageLayout>
              }
            />
          </Route>
          <Route element={<AuthRoute />}>
            <Route
              path={ROUTES.WITHDRAW_INFO()}
              element={
                <PageLayout>
                  <ContentLayout>
                    <ResponsiveLayout>
                      <SectionLayout indent>
                        <WithdrawInfo />
                        <WithdrawStatus />
                      </SectionLayout>
                    </ResponsiveLayout>
                  </ContentLayout>
                </PageLayout>
              }
            />
          </Route>
          <Route element={<AuthRoute />}>
            <Route
              path={ROUTES.DEPOSIT_LIST}
              element={
                <PageLayout align="top">
                  <ContentLayout>
                    <ResponsiveLayout size="medium">
                      <SectionLayout indent>
                        <DepositList />
                      </SectionLayout>
                    </ResponsiveLayout>
                  </ContentLayout>
                </PageLayout>
              }
            />
          </Route>
          <Route element={<AuthRoute />}>
            <Route
              path={ROUTES.DEPOSIT_INFO()}
              element={
                <PageLayout>
                  <ContentLayout>
                    <ResponsiveLayout>
                      <SectionLayout indent>
                        <DepositInfo />
                      </SectionLayout>
                    </ResponsiveLayout>
                  </ContentLayout>
                </PageLayout>
              }
            />
          </Route>
        </Route>
        <Route element={<AuthRoute />}>
          <Route
            path={ROUTES.STATISTICS}
            element={
              <PageLayout align="top">
                <ContentLayout>
                  <ResponsiveLayout size="medium">
                    <SectionLayout indent>
                      <StatisticsProfit />
                    </SectionLayout>
                  </ResponsiveLayout>
                </ContentLayout>
              </PageLayout>
            }
          />
        </Route>
        <Route element={<AuthRoute />}>
          <Route
            path={ROUTES.BALANCES}
            element={
              <PageLayout align="top">
                <ContentLayout>
                  <ResponsiveLayout size="medium">
                    <SectionLayout indent>
                      <StatisticsUsersBalance />
                      <StatisticsUsersAddBalance />
                      <StatisticsBotsBalance />
                    </SectionLayout>
                  </ResponsiveLayout>
                </ContentLayout>
              </PageLayout>
            }
          />
        </Route>
        <Route element={<AuthRoute />}>
          <Route
            path={ROUTES.PROJECT_SETTINGS}
            element={
              <PageLayout align="top">
                <ContentLayout>
                  <ResponsiveLayout size="medium">
                    <SectionLayout indent>
                      <ProjectSettings />
                      <ProjectSettingsBots />
                      <ProjectSettingsJobs />
                      <ProjectSettingsTech />
                    </SectionLayout>
                  </ResponsiveLayout>
                </ContentLayout>
              </PageLayout>
            }
          />
        </Route>
        <Route element={<AuthRoute />}>
          <Route
            path={ROUTES.JOBS_STORAGE}
            element={
              <PageLayout align="top">
                <ContentLayout>
                  <ResponsiveLayout size="full">
                    <SectionLayout indent>
                      <Jobs />
                    </SectionLayout>
                  </ResponsiveLayout>
                </ContentLayout>
              </PageLayout>
            }
          />
        </Route>
        <Route element={<AuthRoute />}>
          <Route
            path={ROUTES.STORAGE}
            element={
              <PageLayout align="top">
                <ContentLayout>
                  <ResponsiveLayout size="full">
                    <SectionLayout indent>
                      <Storage />
                    </SectionLayout>
                  </ResponsiveLayout>
                </ContentLayout>
              </PageLayout>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};
