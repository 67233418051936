import React from "react";
import styled from "styled-components";
import { ButtonDanger, ButtonPrimary } from "../../components/buttons";
import { SkeletonDataList } from "../../components/skeletons";
import { IndentLayout } from "../../components/layouts";
import { ListTitle } from "../../components/titles";
import { Loader, Text } from "../../components";
import { Box } from "../../components/cards";

export const ProjectSettingsJobsView = ({
  handleJobsEnable,
  isJobsEnabled,
  updating,
  loading,
}) => {
  return (
    <Box variant="outlined">
      <IndentLayout>
        {loading ? (
          <SkeletonDataList size="small" />
        ) : (
          <>
            {updating ? <Loader /> : null}
            <ListTitle tid="PROJECT_SETTINGS.JOBS.TITLE" />
            <ButtonContainer>
              {isJobsEnabled ? (
                <ButtonDanger
                  onClick={handleJobsEnable}
                  variant="outlined"
                  disabled={loading || updating}
                >
                  <Text tid="PROJECT_SETTINGS.JOBS.BUTTON_REJECT" />
                </ButtonDanger>
              ) : (
                <ButtonPrimary
                  onClick={handleJobsEnable}
                  variant="outlined"
                  disabled={loading || updating}
                >
                  <Text tid="PROJECT_SETTINGS.JOBS.BUTTON_APPROVE" />
                </ButtonPrimary>
              )}
            </ButtonContainer>
          </>
        )}
      </IndentLayout>
    </Box>
  );
};

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
`;
