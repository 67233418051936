import { axios } from "../utils/request";
import { API } from "../constants/api";
import { STATISTICS_USERS_ADD_BALANCE } from "./index";

const setFail = (message) => ({
  type: STATISTICS_USERS_ADD_BALANCE.LOAD_FAIL,
  message,
});

const setSuccess = () => ({
  type: STATISTICS_USERS_ADD_BALANCE.LOAD_SUCCESS,
});

const setLoading = () => ({
  type: STATISTICS_USERS_ADD_BALANCE.LOAD_PENDING,
});

export const addStatisticsUsersBalance = ({ currency, usernames, sum }) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const url = API.STATISTICS_USERS_ADD_BALANCE();
      await axios.post(url, { amount: sum, usernames, currency });
      dispatch(setSuccess());
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
